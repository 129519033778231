import {
    Consent,
    SessionData,
    PageLoad,
    PageAdvance,
    UserAnswer
} from './tracking-events';

import { trackPageFocusTime } from './functions';

import { update, getMany, delMany } from 'idb-keyval';
import { sendDataToKafka } from './kafka';
import globalData from './data';

const map = {
    'page_load': PageLoad,
    'user_session': SessionData,
    'page_advance': PageAdvance,
    'user_answer': UserAnswer
}
const queued_events_key = 'queued_events';
const user_answered_key = 'user_answered';
const answer_previous_pageload_key = 'answer_previous_pageload';
let keys = [queued_events_key, user_answered_key, answer_previous_pageload_key];

trackPageFocusTime();

const eventsWithPreviousPageLoad = [UserAnswer.type];

const getServerEvents = () => {
    const tags = document.querySelectorAll('meta[name^="evt:"]');
    const configs = Array.from(tags).map((tag) => {
        const data = JSON.parse(atob(tag.attributes.content.value));

        Object.keys(data)
            .filter(k => ['ext_id', 'offer_url_id'].includes(k))
            .forEach(k => data[k] = String(data[k]));

        return {
            name: tag.attributes.name.value.split('evt:').pop(),
            data,
        }
    });

    tags.forEach((t) => t.remove()); // cleanup

    return configs.map((c) => {
        const target = map[c.name];
        globalData.set(target.type, structuredClone(c.data));

        return target.fromServerParameters(c.data);
    });
}

const attachPageAdvanceListeners = () => {
    const uuid = document.querySelector('meta[name="uuid"]').attributes.content.value;

    const nodes = document.querySelectorAll('[data-tpa]');
    nodes.forEach((n) => {
        n.addEventListener('click', () => {
            update(queued_events_key, (v = []) => {
                if (!v) {
                    v = [];
                }
                v.push(PageAdvance.fromServerParameters({uuid, source: n.dataset.tpa}));

                return v;
            });
        });
    });
}

const question_answer_key = 'question_answer';
const attachUserAnswerListener = () => {
    const answerNodes = document.querySelectorAll('[data-tpa=question_page_answer]');

    answerNodes.forEach((n) => {
        n.addEventListener('click', (e) => {
            const urlChunks = !!e.target.getAttribute('href')
                ? e.target.getAttribute('href').split('/')
                : e.target.closest('[data-tpa=question_page_answer]').getAttribute('href').split('/');
            const userAnswer = [
                urlChunks[urlChunks.length - 2], // question token
                urlChunks[urlChunks.length - 1], // answer token
                Date.now(),
            ];

            localStorage.setItem(question_answer_key, `/${userAnswer[0]}/${userAnswer[1]}`);
            update(user_answered_key, (v) => userAnswer);
        });
    });
}

const trackPageDepth = () => {
    const keyPd = 'pd';
    const keyPdTTL = 'pdTTL';
    const nowDate = new Date();
    const isPageDepthExpired = (localStorage.getItem(keyPdTTL) && (new Date(localStorage.getItem(keyPdTTL)).getTime() < nowDate.getTime()));

    let valPd = localStorage.getItem(keyPd) ?? null;

    if (!localStorage.getItem(keyPdTTL) || isPageDepthExpired) {
        nowDate.setDate(nowDate.getDate() + 1);
        localStorage.setItem(keyPdTTL, nowDate.toUTCString());
        valPd = null;
    }

    if (valPd) {
        localStorage.setItem(keyPd, (parseInt(valPd) + 1).toString());
    } else {
        localStorage.setItem(keyPd, '1');
    }
}

const attachConsentListener = () => {
    const pageLoadEvent = document.querySelector('meta[name^="evt:page_load"]');

    if(!pageLoadEvent) {
        return;
    }

    const { uuid: pageLoadId } = JSON.parse(atob(pageLoadEvent.attributes.content.value));

    // consent events tracking
    if (typeof window.__tcfapi !== 'undefined') {
        window.__tcfapi('addEventListener', 2, function (tcData, success) {
            if (!success) {
                return;
            }

            if (tcData.cmpStatus === 'loaded') {
                let initiated = !!window.__cmpInit;
                if (!initiated) {
                    window.__cmpInit = true;
                    sendDataToKafka([{
                        type: Consent.type,
                        data: ['cmp_initiated', pageLoadId, Date.now(), null]
                    }]);
                }
            }

            if (tcData.eventStatus === 'cmpuishown') {
                sendDataToKafka([{
                    type: Consent.type,
                    data: ['cmp_dialog_loaded', pageLoadId, Date.now(), null]
                }]);
            }

            if (tcData.eventStatus === 'useractioncomplete' || tcData.eventStatus === 'tcloaded') {
                const eventTypeName =  (tcData.eventStatus === 'useractioncomplete') ? 'cmp_consent_given' : 'cmp_consent_preexists';

                let consentValues = [];
                if (tcData.purpose.consents && typeof tcData.purpose.consents === 'object') {
                    consentValues = consentValues.concat(Object.values(tcData.purpose.consents));
                }

                if (tcData.specialFeatureOptins && typeof tcData.specialFeatureOptins === 'object') {
                    consentValues = consentValues.concat(Object.values(tcData.specialFeatureOptins));
                }

                for (let index in consentValues) {
                    if (consentValues[index] === true) {
                        sendDataToKafka([{
                            type: Consent.type,
                            data: [eventTypeName, pageLoadId, Date.now(), null]
                        }]);

                        break;
                    }
                }
            }
        });
    }

    // ads request tracking as a part of consent queue
    if (typeof googletag !== 'undefined') {
        googletag.cmd.push(function () {
            googletag.pubads().addEventListener('slotRequested', (event) => {
                if (!!event.slot) {
                    sendDataToKafka([{
                        type: Consent.type,
                        data: ['ad_request', pageLoadId, Date.now(), event.slot.getAdUnitPath()]
                    }]);
                }
            });
        });
    }
};

getMany(keys)
    .then(([events = [], userAnswered, answerPreviousPageLoad]) => {
        getServerEvents().forEach((e) => {
            if (!eventsWithPreviousPageLoad.includes(e.type)) {
                events.push(e);
            }

            // user_answer
            if (e.type === UserAnswer.type) {
                if (!!userAnswered && !!answerPreviousPageLoad) {
                    answerPreviousPageLoad.data.push(...userAnswered);
                    events.push(answerPreviousPageLoad);
                }

                update(answer_previous_pageload_key, () => e);
                keys = keys.filter((v) => v !== answer_previous_pageload_key);
            }
        });

        events = events.map((e) => {
            if (Array.isArray(e.data)) {
                e.data = e.data.map(item => (e.type !== PageAdvance.type && !item) ? '' : item);
            }

            return e;
        });

        // The only way to clone this data without making any changes to the original data is json encode/decode
        const kafkaEvents = structuredClone(events);
        sendDataToKafka(kafkaEvents);
    })
    .then(() => delMany(keys));

attachPageAdvanceListeners();
attachUserAnswerListener();
trackPageDepth();
attachConsentListener();
