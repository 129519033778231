import Cookies from 'js-cookie';

import {
    getOrientation,
    getClientTimeOffset
} from './functions';

const Consent = {
    type: 'consent',
    fromServerParameters: ()  => ({
        type: Consent.type,
        data: []
    })
}

const SessionData = {
    type: 'session',
    referer: Cookies.get('referer'),
    // userLanguage: Navigator.language,
    resolution: `${window.screen.width * window.devicePixelRatio}x${window.screen.height * window.devicePixelRatio}`,
    orientation: getOrientation(),
    fromServerParameters: server => ({
        type: SessionData.type,
        data: [
            server.session_id,
            server.session_created_at,
            server.user_id,
            server.language,
            SessionData.referer,
            server.country,
            server.browser_name,
            server.browser_version,
            server.os_name,
            server.os_version,
            server.device,
            server.device_model,
            SessionData.resolution,
            SessionData.orientation,
            server.offer_url_id,
            server.ext_id,
            server.params_metadata,
            server.content_token,
            server.experience,
            server.context,
            server.battle_leaderboard_token
        ],
    }),
}

const PageLoad = {
    type: 'page_load',
    reloaded: performance.navigation.type === performance.navigation.TYPE_RELOAD,
    fromServerParameters: server => ({
        type: PageLoad.type,
        data: [
            server.hash,
            server.session_id,
            server.user_id,
            server.uuid,
            server.language,
            server.subdomain,
            server.device,
            server.country,
            server.offer_url_id,
            server.ext_id,
            server.content_token,
            server.round_number,
            server.question_token,
            server.experience,
            server.context,
            server.battle_leaderboard_token,
            PageLoad.reloaded,
            server.server_time_ms,
            getClientTimeOffset(server.server_time_ms, true)
        ],
    }),
}

const PageAdvance = {
    type: 'page_advance',
    fromServerParameters: ({uuid, source}) => ({
        type: PageAdvance.type,
        data: [
            uuid,
            window.focusTime,
            source, // todo: add some kind of default value per page
            Date.now()
        ]
    })
}

const UserAnswer = {
    type: 'answers',
    fromServerParameters: server => ({
        type: UserAnswer.type,
        data: [
            server.session_id,
            server.user_id,
            server.uuid,
            server.device,
            server.content_token,
            server.round_number,
            server.round_question_number,
            server.experience,
            server.context,
            server.battle_leaderboard_token,
            server.server_time_ms,
            Date.now()
        ],
    })
}

export {
    Consent,
    SessionData,
    PageLoad,
    PageAdvance,
    UserAnswer
};
