import env from './env'
import {isObject} from './functions';

export function sendDataToKafka(dataToSend) {
    const hasCorrectFormat = Array.isArray(dataToSend) && dataToSend.length;

    if (!hasCorrectFormat) {
        return;
    }

    const envPrefix = env.server !== 'prod' ? `${env.server}_` : '';

    try {
        dataToSend.forEach((queue) => {
            let kafkaDataFormat = {records: []};
            let topicName = queue.type;

            let queueData = queue.data;

            if (isObject(queueData)) {
                queueData = Object.values(queueData);
            }

            topicName = `${envPrefix}quizhero_${topicName}`;

            kafkaDataFormat.records.push({
                'key': topicName,
                'value': queueData
            });

            const serverUri = `${env.kafka_url}topics/${topicName}`;
            fetch(serverUri, {
                method: "POST",
                headers: {'Content-Type': 'application/vnd.kafka.json.v2+json'},
                body: JSON.stringify(kafkaDataFormat)
            });
        });
    } catch (error) {
        console.log('Kafka:' + error);
    }
}
