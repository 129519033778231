"use strict";

export const getOrientation = () => {
    if (matchMedia('(orientation: landscape)').matches) {
        return 'landscape'
    }

    if (matchMedia('(orientation: portrait)').matches) {
        return 'portrait'
    }

    return '';
}

export const getTimestamp = () => {
    return (Math.floor(Date.now() / 1000));
}

export const getTimestampInISO = () => {
    return (new Date()).toISOString();
}

export const getClientTimeOffset = (server, milliseconds = false) => {
    if (milliseconds) {
        return getTimestampInMilliseconds() - server;
    }

    return getTimestamp() - server;
}

export const getTimestampInMilliseconds = (tenthOfSecond = false) => {
    const now = Date.now();
    return tenthOfSecond ? (now / 1000) : now;
}

export const trackPageFocusTime = () => {
    window.focusTime = 0;
    let interval;

    const start = () => interval = setInterval(() => window.focusTime++, 1000);
    const stop  = () => clearInterval(interval);

    window.onfocus = start;
    window.onblur  = stop;

    start();
}

export const getTime = (start) => {
    const now = new Date().getTime();
    const diff = start - now;

    if (diff < 0) {
        return null;
    }

    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
};

export const toHtml = (value, firsElement = true) => {
    const dom = new DOMParser().parseFromString(value, 'text/html').body;

    return firsElement ? dom.firstElementChild : dom;
};

export const dispatchEvent = (target, evt, args, eventOptions = {}) => {
    return target.dispatchEvent(new CustomEvent(evt, { bubbles: true, detail: args, ...eventOptions }))
}

export const isTrue = (value) => [1, '1', 'on', 'true', true].includes(value);
export const isBool = (value) => [1, '1', 'on', 'true', true, 0, '0', 'off', 'false', false].includes(value);

export const animateCSS = (element, animation, prefix = '') =>
    new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        const node = element instanceof HTMLElement ? element : document.querySelector(element);

        node.classList.add(`${prefix}animated`, animationName);

        function handleAnimationEnd(event) {
            event.stopPropagation();
            node.classList.remove(`${prefix}animated`, animationName);
            resolve('Animation ended');
        }

        node.addEventListener('animationend', handleAnimationEnd, {once: true});
    });


export const isOnViewport = (element) => {
    const rect = element.getBoundingClientRect();

    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;
    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    const horInView = (rect.left <= windowWidth) && ((rect.left + rect.width) >= 0);

    return vertInView && horInView;
};

export const castOptions = (options) => {
    for (let key in options) {
        let value = options[key];

        if (value === 'true' || value === 'false') {
            value = value === 'true';
        } else if (!isNaN(Number(value))) {
            value = Number(value);
        } else if (typeof value === 'object') {
            value = castOptions(value);
        } else {
            try {
                value = JSON.parse(value);
            } catch (e) {
                //
            }
        }

        options[key] = value;
    }

    return options;
};

export async function request(config) {
    config = Object.assign({
        body: undefined,
        method: 'POST',
        redirect: true,
        withHeaders: false,
        headers: {},
        fetchOptions: {},
        handleResponse: true,
    }, config);

    const requestConfig = {
        method: config.method,
        body: config.body ? JSON.stringify(config.body) : '',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            ...config.headers,
        },
        ...config.fetchOptions,
    };

    if (config.method === 'GET') {
        delete requestConfig.body;
    }

    return await fetch(config.url, requestConfig).then(async response => {
        if (!config.handleResponse) {
            return response;
        }

        if (response.redirected) {
            if (config.redirect) window.location = response.url;

            return;
        }

        if (response.status === 204) {
            if (response.headers.has('X-Target-Location')) {
                window.location = response.headers.get('X-Target-Location');

                return;
            }

            if (config.withHeaders) {
                return { headers: response.headers, value: undefined };
            }

            return;
        }

        if (requestConfig.headers.Accept.includes('text/html') && response.status === 200) {
            return response.text().then(text => toHtml(text, false));
        }

        return response.json().then(value => {
            if (!(response.status >= 400 && response.status <= 599)) {
                if (config.withHeaders) {
                    return { headers: response.headers, value };
                }

                return value;
            }

            const error = new Error(response.statusText);
            error.response = response;
            error.jsonValue = value;
            error.statusCode = response.status;

            if (response.headers.has('retry-after')) {
                error.retryAfter = response.headers.get('retry-after');
            }

            throw error;
        });
    });
}

export function isObject(value) {
    return value !== null && Object.prototype.toString.call(value) === '[object Object]';
}

export function countAdResponse (counter = {}, slotKey) {
    counter[slotKey] = (parseInt(counter[slotKey]) + 1) || 0;

    return counter;
}

const disableVideoAds = () => {
    const elements = document.querySelectorAll('.vjs-gmm-skin');

    if (elements.length === 0) return;

    elements.forEach(element => {
        element.player.ima.getAdsManager()?.pause();
        element.style.setProperty('display', 'none');
    });
}

const enableVideoAds = () => {
    const elements = document.querySelectorAll('.vjs-gmm-skin');

    if (elements.length === 0) return;

    elements.forEach(element => {
        element.player.ima.getAdsManager()?.resume();
        element.style.removeProperty('display');
    });
}

export function checkVisibility(element) {
    if (!Element.prototype.checkVisibility) {
        Element.prototype.checkVisibility = function (options = {}) {
            const computedStyle = window.getComputedStyle(this);

            // Check if display is 'none', visibility is 'hidden' or 'collapse', or opacity is 0
            if (computedStyle.display === 'none' ||
                computedStyle.visibility === 'hidden' ||
                computedStyle.visibility === 'collapse' ||
                computedStyle.opacity === '0') {
                return false;
            }

            // Check if element has zero width/height (not visible)
            const rect = this.getBoundingClientRect();
            if (rect.width === 0 || rect.height === 0) {
                return false;
            }

            // Check if the element is within the viewport (optional)
            if (options.checkOpacity !== false) {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach(entry => {
                        if (entry.intersectionRatio === 0) {
                            return false;
                        }
                    });
                });
                observer.observe(this);
            }

            return true;
        };
    }

    return element.checkVisibility();
}
