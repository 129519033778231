const elementMap = new Map()

export default {
    get(key) {
        return elementMap.has(key) ? elementMap.get(key) : null;
    },

    set(key, value) {
        elementMap.set(key, value);
    },

    remove(key) {
        if (!elementMap.has(key)) {
            return;
        }

        elementMap.delete(key);
    },

    all() {
        return Object.fromEntries(elementMap.entries());
    },
}
